<template>
  <section class="intro-x">
    <div class="w-full my-5">
      <back-button />
    </div>
    <div class="w-full">
      <Timeline
      :value="getStates"
      align="alternate"
      >
        <template #content="slotProps">
          <div v-if="slotProps.item.state === 3" class="font-bold uppercase text-sm">Anulado en la sede</div>
          <div v-else-if="slotProps.item.state === 5" class="font-bold uppercase text-sm">Rechazado</div>
          <div class="font-bold uppercase text-sm">{{slotProps.item.justification}}</div>
          <div class="text-gray-600 text-sm"> {{$h.formatDate(slotProps.item.createdAt,'DD MM YYYY HH:mm')}} </div>
        </template>
      </Timeline>
    </div>
  </section>
</template>

<script>
import { computed, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import requestsBuscar from '../../../../services/requestsBuscar'
import Timeline from 'primevue/timeline'

export default {
  name: 'History',
  components: {
    Timeline
  },
  setup () {
    const route = useRoute()
    const request = ref({
      id: null,
      details: [],
      states: []
    })
    const getRequests = computed(() => {
      return request.value
    })
    const setRequests = (value) => {
      request.value = value
    }
    const getStates = computed(() => {
      return request.value.states
    })
    const fetchOrder = () => {
      const { id } = route.params
      requestsBuscar(id).then(({ data }) => {
        setRequests(data)
      }).catch(err => {
        console.error(err.message)
      })
    }

    onMounted(() => {
      fetchOrder()
    })
    return {
      getRequests,
      getStates
    }
  }
}
</script>
